<template>
  <!-- start MovementDialog -->
  <div class="modal fade" id="id-event-form" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <template v-if="!movement">Create Movement</template>
            <template v-if="movement">Edit Movement {{ movement.event_id }}</template>
          </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-body">

            <div class="form-group">
              <label>Started At</label>
              <div class="input-group">
                <datepicker v-model="startedAtDate.value"
                            inputFormat="dd/MM/yyyy"
                            class="form-control"
                            :class="{ 'is-valid': startedAtDate.isValid, 'is-invalid': startedAtDate.isValid === false }"
                            placeholder="Pick date"
                            @blur="validateStartedAtDate()"></datepicker>

                <input v-model="startedAtTime.value"
                       type="time" step="1"
                       class="form-control"
                       :class="{ 'is-valid': startedAtTime.isValid, 'is-invalid': startedAtTime.isValid === false }"
                       @change="validateStartedAtTime()"/>
              </div>
            </div>

            <div class="form-group">
              <label>Finished At</label>
              <div class="input-group">
                <datepicker v-model="finishedAtDate.value"
                            inputFormat="dd/MM/yyyy"
                            class="form-control"
                            :class="{ 'is-valid': finishedAtDate.isValid, 'is-invalid': finishedAtDate.isValid === false }"
                            placeholder="Pick date"
                            @blur="validateFinishedAtDate()"></datepicker>


                <input v-model="finishedAtTime.value"
                       type="time" step="1"
                       class="form-control"
                       :class="{ 'is-valid': finishedAtTime.isValid, 'is-invalid': finishedAtTime.isValid === false }"
                       @change="validateFinishedAtTime()"/>
              </div>
            </div>


            <div class="form-group">
              <p class="mt-2">Select all that apply.</p>

              <h5>Event type</h5>
              <div class="form-check" v-for="type in movementTypes">
                <input class="form-check-input" type="radio" name="movementTypes" :id="'event-create-' + type.event_name"
                       :value="type.event_type_id"
                       v-model="eventType.value"
                       @change="eventType.isValid = true">
                <label class="form-check-label" :for="'event-create-' + type.event_name">
                  This was a {{ type.event_name }}
                </label>
              </div>

              <h5 class="mt-2">Aircraft type</h5>
              <div class="form-check" v-for="type in aircraftTypes">
                <input class="form-check-input" type="radio" name="aircraftType" :id="'event-create-' + type"
                       :value="type"
                       v-model="aircraftType.value"
                       @change="aircraftType.isValid = true">
                <label class="form-check-label" :for="'event-create-' + type">
                  {{ stringifySlug(type) }}
                </label>
              </div>

              <h5 class="mt-2">Additional information</h5>
              <div class="input-group">
                <div class="form-check">
                  <input class="form-check-input"
                         type="checkbox"
                         id="event-create-resident"
                         v-model="isResident"
                         @change="validateResidentDetails()">
                  <label class="form-check-label" for="event-create-resident">
                    This aircraft is a resident
                  </label>
                </div>
              </div>

              <div class="input-group" v-show="isResident && regOrTransMissing">
                <input type="text"
                       class="form-control"
                       :class="{ 'is-valid': transponder.isValid, 'is-invalid': transponder.isValid === false }"
                       placeholder="Enter transponder number"
                       v-model="transponder.value"
                       @blur="validateTransponder()"
                />
                <input type="text"
                       class="form-control"
                       :class="{ 'is-valid': registration.isValid, 'is-invalid': registration.isValid === false }"
                       placeholder="Enter registration number"
                       v-model="registration.value"
                       @blur="validateRegistration()"
                />
              </div>

              <!-- TODO: check if this is required in the metrics -->
              <!--                <div class="form-check">-->
              <!--                    <input class="form-check-input" type="checkbox" id="circuitCreate" value="circuit">-->
              <!--                    <label class="form-check-label" for="circuitCreate">-->
              <!--                        This aircraft was participating in circuit training-->
              <!--                    </label>-->
              <!--                </div>-->

              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="event-create-false-positive"
                       v-model="isFalsePositive.value"
                       @change="isFalsePositive.isValid = true">
                <label class="form-check-label" for="event-create-false-positive">
                  This was a false positive
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="event-create-emergency"
                       v-model="isEmergency.value"
                       @change="isEmergency.isValid = true">
                <label class="form-check-label" for="event-create-emergency">
                  This was an emergency
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="event-create-breaches-code"
                       v-model="isBreach.value"
                       @change="isBreach.isValid = true">
                <label class="form-check-label" for="event-create-breaches-code">
                  This breached the code of conduct
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="event-create-notes">Notes</label>
              <textarea class="form-control" id="event-create-notes" rows="2"
                        v-model="notes.value"
                        @change="notes.isValid = true"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" :disabled="!formValidated || saving" @click="saveMovement">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end MovementDialog -->
</template>

<script>
import UtilService from "@/services/UtilService";
import APIService from "@/services/APIService";

const initialData = {
  startedAtDate:  {
    value:   new Date(),
    error:   false,
    isValid: undefined
  },
  startedAtTime:  {
    value:   null,
    error:   false,
    isValid: undefined
  },
  finishedAtDate: {
    value:   new Date(),
    error:   false,
    isValid: undefined
  },
  finishedAtTime: {
    value:   null,
    error:   false,
    isValid: undefined
  },
  transponder: {
    value: null,
    error: false,
    isValid: undefined
  },
  registration: {
    value: null,
    error: false,
    isValid: undefined
  },
  eventType: {
    value: null,
    error: false,
    isValid: undefined
  },
  aircraftType: {
    value: null,
    error: false,
    isValid: undefined
  },
  isFalsePositive: {
    value: false,
    error: false,
    isValid: true
  },
  isCircuit: {
    value: false,
    error: false,
    isValid: true
  },
  isEmergency: {
    value: false,
    error: false,
    isValid: true
  },
  isBreach: {
    value: false,
    error: false,
    isValid: true
  },
  notes: {
    value: null,
    error: false,
    isValid: true
  },
  isResident: false,
  error:      null,
  saving:     false,
}

export default {
  name: "MovementDialogue",
  props: {
    movement: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      startedAtDate:  {...initialData.startedAtDate},
      startedAtTime:  {...initialData.startedAtTime},
      finishedAtDate: {...initialData.finishedAtDate},
      finishedAtTime: {...initialData.finishedAtTime},
      transponder: {...initialData.transponder},
      registration: {...initialData.registration},
      eventType: {...initialData.eventType},
      aircraftType: {...initialData.aircraftType},
      isFalsePositive: {...initialData.isFalsePositive},
      isCircuit: {...initialData.isCircuit},
      isEmergency: {...initialData.isEmergency},
      isBreach: {...initialData.isBreach},
      notes: {...initialData.notes},
      isResident: initialData.isResident,
      error:      initialData.error,
      saving:     initialData.saving,
      movementTypes: [],
      aircraftTypes: [],
      inputFields: ["startedAtDate", "startedAtTime", "finishedAtDate", "finishedAtTime", "transponder", "registration",
        "eventType", "aircraftType", "isFalsePositive", "isCircuit", "isEmergency", "isBreach", "notes"]
    }
  },
  computed: {
    formValidated() {
      /*
       * Fields that have been modified will have an isValid of true or false, fields that haven't been touched will
       * have isValid = undefined if a value is required, or isValid = true otherwise.
       */

      if(this.movement) {
      /*
       * Edit - only edited fields need to be validated, we can trust unedited ones (isValid = undefined), so we just
       * check none have isValid = false.
       */
        return !this.inputFields.some(field => this[field].isValid === false);
      }

      /*
       * Create - all fields need to be individually validated.
       */
      const residentValid = !this.isResident || (this.isResident && this.registration.isValid && this.transponder.isValid);
      return this.startedAtDate.isValid
          && this.startedAtTime.isValid
          && this.finishedAtDate.isValid
          && this.finishedAtTime.isValid
          && this.eventType.isValid
          && this.aircraftType.isValid
          && this.isFalsePositive.isValid
          && this.isCircuit.isValid
          && this.isEmergency.isValid
          && this.isBreach.isValid
          && this.notes.isValid
          && residentValid;
    },
    regOrTransMissing() {
      return !this.movement?.registration || !this.movement?.transponder;
      //return !this.registration.value || !this.transponder.value;
    }
  },
  watch: {
    movement: function(movement) {

      for(const field of this.inputFields) {
        this[field] = {...initialData[field]};
      }
      this.isResident = initialData.isResident;

      if(movement) {
        this.startedAtDate.value = UtilService.sqlDateTimeStringToDate(movement.started_at);
        this.startedAtTime.value = movement.started_at.substring(movement.started_at.length - 8);
        this.finishedAtDate.value = UtilService.sqlDateTimeStringToDate(movement.finished_at);
        this.finishedAtTime.value = movement.finished_at.substring(movement.finished_at.length - 8);
        this.transponder.value = movement.transponder;
        this.registration.value = movement.registration;
        this.eventType.value = movement.event_type_id
        this.aircraftType.value = movement.aircraft_type;
        this.isFalsePositive.value = movement.is_false_positive;
        this.isCircuit.value = movement.is_circuit;
        this.isEmergency.value = movement.is_emergency;
        this.isBreach.value = movement.is_breach;
        this.notes.value = movement.notes;

        this.isResident = !!movement.resident_id;
      }
    }
  },
  methods: {
    stringifySlug: UtilService.stringifySlug,
    validateStartedAtDate: function() {
      this.startedAtDate.isValid = this.startedAtDate.value instanceof Date;
    },
    validateStartedAtTime: function() {
      this.startedAtTime.isValid = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]+$/.test(this.startedAtTime.value);
      if(this.startedAtTime.isValid) {
        const [h,m,s] = this.startedAtTime.value.split(":");
        this.startedAtDate.value.setHours(h);
        this.startedAtDate.value.setMinutes(m);
        this.startedAtDate.value.setSeconds(s);
        this.validateStartedAtDate();
      }
    },
    validateFinishedAtDate: function() {
      this.finishedAtDate.isValid = this.finishedAtDate.value instanceof Date;
    },
    validateFinishedAtTime: function() {
      this.finishedAtTime.isValid = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]:[0-5][0-9]+$/.test(this.finishedAtTime.value);
      if(this.finishedAtTime.isValid) {
        const [h,m,s] = this.finishedAtTime.value.split(":");
        this.finishedAtDate.value.setHours(h);
        this.finishedAtDate.value.setMinutes(m);
        this.finishedAtDate.value.setSeconds(s);
        this.validateFinishedAtDate();
      }
    },
    validateTransponder: function() {
      if(!this.transponder.value || !/^[0-9A-Fa-f]{6}$/.test(this.transponder.value)) {
        this.transponder.isValid = false;
      } else {
        this.transponder.isValid = true;
        this.transponder.value = this.transponder.value.toUpperCase();
      }
    },
    validateRegistration: function() {
      if(!this.registration.value) {
        this.registration.isValid = false;
      } else {
        this.registration.isValid = true;
        this.registration.value = this.registration.value.toUpperCase();
      }
    },
    validateResidentDetails: function() {
      if(this.isResident && this.regOrTransMissing) {
        this.validateRegistration();
        this.validateTransponder();
      } else {
        this.registration.isValid = undefined;
        this.transponder.isValid = undefined;
      }
    },
    saveMovement: async function() {
      if(!this.formValidated) return;

      this.error = null;
      this.saving = true;

      const fields = {
        started_at: UtilService.dateToSqlDateTimeString(this.startedAtDate.value),
        finished_at: UtilService.dateToSqlDateTimeString(this.finishedAtDate.value),
        transponder: this.transponder.value,
        registration: this.registration.value,
        event_type_id: this.eventType.value,
        aircraft_type: this.aircraftType.value,
        is_false_positive: this.isFalsePositive.value,
        is_circuit: this.isCircuit.value,
        is_emergency: this.isEmergency.value,
        is_breach: this.isBreach.value,
        notes: this.notes.value,
        resident_id: this.movement?.resident_id ?? null
      };

      if(!this.isResident && this.movement?.resident_id) {
        fields.resident_id = null;
      }

      if(this.isResident && !this.movement?.resident_id) {
        const residents = await APIService.getResidents({
          fields: {
            transponder: this.transponder.value,
            registration: this.registration.value,
            deleted: null
          }
        });
        if(residents.length > 0) {
          fields.resident_id = residents[0].resident_id;
        } else {
          fields.resident_id = await APIService.addResident(this.transponder.value, this.registration.value);
        }
      }

      if(Object.keys(fields).length === 0) {
        this.close();
        this.saving = false;
        return;
      }

      try {
        if(this.movement) {
          await APIService.editMovement(this.movement.event_id, fields);
        } else {
          await APIService.addMovement(fields);
        }

        this.close();
      } catch(e) {
        this.error = e.message;
      }
      this.saving = false;
    },
    close() {
      $('#id-event-form').modal('hide');
    }
  },
  created() {
    APIService.getMovementTypes().then(movementTypes => this.movementTypes = movementTypes);
    APIService.getAircraftTypes().then(aircraftTypes => this.aircraftTypes = aircraftTypes);
  }
}

</script>

<style scoped>

</style>