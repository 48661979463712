<template>
  <!-- start Movement -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
            class="card-body"
            :class="movement.is_emergency || movement.is_breach ? 'bg-emergency' : 'bg-standard'"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="d-md-flex align-items-center">
                  <div class="pr-1">
                    <template v-if="touchAndGo">
                      <i class="fas fa-2x fa-plane-arrival"></i>
                      <i class="fas fa-2x fa-plane-departure"></i>
                    </template>
                    <template v-else>
                      <i
                          class="fas fa-2x"
                          :class="movementClass"
                      ></i>
                    </template>
                  </div>
                  <div class="pr-1">
                    <h4>{{formattedAircraftType}} {{movement.event_name}} [ID: {{movement.event_id}}]
                      <i v-if="isResident" class="fas fa-home" title="Resident"></i>&nbsp;
                      <i v-if="movement.is_emergency" class="fas fa-first-aid text-danger" title="Emergency"></i>
                      <i v-if="movement.is_breach"  class="fas fa-exclamation-circle text-danger" title="Breached code of conduct"></i>
                    </h4></div>
                  <div class="pr-1">
                    <button type="button"
                            class="btn btn-info modal-event"
                            data-toggle="modal"
                            data-target="#id-event-form"
                            @click="$emit('edit-movement', movement.event_id)">Details</button>
                  </div>
                  <div class="pr-1">
                    <button type="button" class="btn btn-info modal-event" data-toggle="modal" data-target="#id-flight-map"
                            @click="$emit('show-flight-map', movement.event_id)">Map</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-12">
                <p class="card-text">
                  {{movement.started_at}} | {{movement.registration || '??????'}} | {{movement.transponder || '??????'}}</p>
              </div>
            </div>
            <div class="row row-smaller-gutters">
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 1"-->
<!--                     id='id-image1' />-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 2"-->
<!--                     id='id-image2' />-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 3"-->
<!--                     id='id-image3' />-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 4"-->
<!--                     id='id-image4' />-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 5"-->
<!--                     id='id-image5' />-->
<!--              </div>-->
<!--              <div class="col-lg-2 col-md-4 col-sm-12 mb-1 mb-lg-0">-->
<!--                <img class="img-thumbnail img-fluid border-0 modal-img" src="https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg" alt="GF-123Y - Camera 6"-->
<!--                     id='id-image6' />-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end Movement -->

</template>

<script>
export default {
  name: "movement",
  props: {
    /**
     * @type {{
     *   event_id: Number,
     *   event_name: String,
     *   aircraft_type: String,
     *   transponder: String,
     *   registration: String,
     *   resident_id: Number,
     *   is_emergency: Boolean,
     *   is_breach: Boolean,
     *   started_at: String,
     *   finished_at: String
     * }}
     */
    movement: Object,
    images: Object
  },
  computed: {
    movementClass() {
      if(this.formattedAircraftType === 'Helicopter'){
        return 'fa-helicopter'
      }
      let className;
      switch(this.movement.event_name){
        case "landing":
          className = 'fa-plane-arrival';
          break;
        case "take-off":
          className = 'fa-plane-departure';
          break;
        case "hot-refuel":
          className = 'fa-gas-pump text-danger';
          break;
        default:
          className = 'fa-question-circle text-warning';
          break;
      }
      return className;
    },
    touchAndGo() {
      return this.movement.event_name === 'touch-go'
    },
    formattedAircraftType() {
      if (this.movement.aircraft_type.length === 0) return '';
      return this.movement.aircraft_type.charAt(0).toUpperCase() + this.movement.aircraft_type.slice(1).replace('_', '-');
    },
    isResident() {
      return this.movement.resident_id !== null;
    }
  }
}

$( document ).ready(function() {
  // Image Modals
  const modalImages = $('.modal-img');
  modalImages.each(function (index, modalImgHtml) {
    const modalImg = $(modalImgHtml);
    const modal = $('' +
        '<div class="modal-overlay">' +
        '<span class="modal-close">&times;</span>' +
        '<img class="modal-content" />' +
        '<div class="modal-caption"></div>' +
        '</div>'
    );
    modal.id = modalImg.attr('id') + '-modal';
    modal.children('img').attr('src', modalImg.attr('src'));
    modal.children('.modal-caption').html(modalImg.attr('alt'));
    modal.children('.modal-close').click(function () {
      modal.hide();
    });
    modalImg.after(modal);
    modalImg.click(function () {
      modal.show();
    });
  });
});

</script>

<style scoped>

</style>
