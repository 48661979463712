<template>
  <div class="modal fade" id="id-flight-map" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{movement.registration}} {{formattedAircraftType}} {{movement.event_name}} [ID: {{movement.event_id}}]</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="map"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="toggleTileset">Show {{tileset === 'osm' ? 'ESRI Satelite' : 'Open Street'}} Map</button>
          <button type="button" class="btn btn-primary" @click="toggleMarkers">{{showMarkers ? 'Hide' : 'Show'}} Data Points</button>
          <button type="button" class="btn btn-primary" @click="toggleGuides">{{showGuides ? 'Hide' : 'Show'}} Guides</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import APIService from "@/services/APIService";

const BAGBY_LATITUDE = '54.21175000';
const BAGBY_LONGITUDE = '-1.29101000';
const BAGBY_RADIUS = 3701.491; // 2.3 miles in metres

const MARKER_ICON = L.icon({
  iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
  iconAnchor: [11, 41]
});

const RADARCAPE_USER_ID_MAP = {
  2: 'Bagby',
  3: 'Radarcape 2',
  4: 'Keeper\'s Cottage',
  5: 'Glider Club',
  6: 'Tim B',
};

export default {
  name: "FlightMap",
  props: {
    colour: {
      type: String,
      required: false,
      default: '#000000',
      validator: value => /^#([0-9A-F]{3}){1,2}$/i.test(value)
    },
    movement: {
      type: Object,
      required: true
    },
  },
  methods: {

    getDistanceBetweenLongLatPointsInMetres: function(point1, point2) {
      const r = 6366707;
      const a =
              0.5 - Math.cos((point2[1] - point1[1]) * Math.PI / 180)/2 +
              Math.cos(point1[1] * Math.PI / 180) * Math.cos(point2[1] * Math.PI / 180) *
              (1 - Math.cos((point2[0] - point1[0]) * Math.PI / 180))/2;

      return r * 2 * Math.asin(Math.sqrt(a));
    },

    buildFilter: function(hex, from, to, src) {

      let filter = {
        fields: {
          hex: hex,
          uti: {
            min: Math.floor(from.getTime() / 1000),
            max: Math.ceil(to.getTime() / 1000)
          }
        },
        order: {
          uti: 'ASC'
        }
      };

      if(src) {
        filter.fields.src = src;
      }

      return filter;
    },

    initMap: function() {
      this.map = L.map('map').setView([BAGBY_LATITUDE, BAGBY_LONGITUDE], 13);

      this.tilesets[this.tileset].addTo(this.map);

      L.control.scale().addTo(this.map);

      this.guides.push(L.circle([BAGBY_LATITUDE, BAGBY_LONGITUDE], {
        color:       '',
        fillColor:   '#6666aa',
        fillOpacity: 0.3,
        radius:      300
      }));

      this.guides.push(L.circle([BAGBY_LATITUDE, BAGBY_LONGITUDE], {
        color:       '',
        fillColor:   '#6666aa',
        fillOpacity: 0.3,
        radius:      BAGBY_RADIUS
      }));

      const leftPolygon = [[54.21168, -1.29094], [54.20394, -1.29872], [54.21175, -1.30644], [54.21182, -1.29109]];
      this.guides.push(L.polygon(leftPolygon, {color: 'green'}));

      const rightPolygon = [[54.21182, -1.29109], [54.21953, -1.28333], [54.21175, -1.27571], [54.21168, -1.29094]];
      this.guides.push(L.polygon(rightPolygon, {color: 'green'}));
    },

    getData: async function() {
      const startedAt = new Date(this.movement.started_at.replace(' ', 'T')+'Z');
      const finishedAt = new Date(this.movement.finished_at.replace(' ', 'T')+'Z');
      const from = new Date(startedAt.getTime() - 300000);
      const to = new Date(finishedAt.getTime() + 300000);
      const filter = this.buildFilter(this.movement.transponder, from, to, this.src);

      const response = await APIService.getDataEntries(filter);

      this.dataset = response.map(point => {
        let source = RADARCAPE_USER_ID_MAP[point.user_id];
        if(point.src === 'A') source += ' (ADS-B)';
        if(point.src === 'M') source += ' (MLAT)';
        return {
          id: point.data_entry_id,
          registration: point.reg,
          transponder: point.hex,
          timestamp: point.uti,
          latitude: point.lat,
          longitude: point.lon,
          altitude: point.alt,
          speed: point.spd,
          source: source
        };
      });
    },

    redrawFlightPath: function() {

      // Remove any existing data
      if(this.polyline !== null) {
        this.map.removeLayer(this.polyline);
        this.polyline = null;
      }

      this.markers.forEach(marker => this.map.removeLayer(marker));
      this.markers = [];

      // Prepare new data
      const latLngs = [];

      this.dataset.forEach((point) => {
        const location = [point.latitude, point.longitude];
        latLngs.push(location);

        // Add labels
        const label = new Date(point.timestamp * 1000).toLocaleString();
        const marker = new L.marker(location, { opacity: 1, icon: MARKER_ICON });
        marker.bindPopup(
            "<b>" + label + "</b>" +
            "<br />timestamp: " + point.timestamp +
            "<br />id: " + point.id +
            "<br />registration: " + point.registration +
            "<br />transponder: " + point.transponder +
            "<br />latitude: " + point.latitude +
            "<br />longitude: " + point.longitude +
            "<br />altitude: " + point.altitude +
            "<br />speed: " + point.speed +
            "<br />source: " + point.source);
        if(this.showMarkers) {
          marker.addTo(this.map);
        }
        this.markers.push(marker);
      });

      // Add new lines
      this.polyline = L.polyline(latLngs, {color: this.colour}).addTo(this.map);

      this.map.setView([BAGBY_LATITUDE, BAGBY_LONGITUDE], 13);
    },

    addMarkers: function() {
      this.markers.forEach(marker => marker.addTo(this.map));
    },
    removeMarkers: function() {
      this.markers.forEach(marker => this.map.removeLayer(marker));
    },

    addGuides: function() {
      this.guides.forEach(guide => guide.addTo(this.map));
    },
    removeGuides: function() {
      this.guides.forEach(guide => this.map.removeLayer(guide));
    },

    toggleTileset: function() {
      this.tilesets[this.tileset].remove();
      this.tileset = (this.tileset === 'osm') ? 'sat' : 'osm';
      this.tilesets[this.tileset].addTo(this.map);
    },

    toggleMarkers: function() {
      this.showMarkers = !this.showMarkers;
      if(this.showMarkers) {
        this.addMarkers();
      } else {
        this.removeMarkers();
      }
    },

    toggleGuides: function() {
      this.showGuides = !this.showGuides;
      if(this.showGuides) {
        this.addGuides();
      } else {
        this.removeGuides();
      }
    }
  },
  data() {
    return {
      src: null,
      map: null,
      dataset: {},
      polyline: null,
      markers: [],
      showMarkers: true,
      guides: [],
      showGuides: false,
      tileset: 'osm',
      tilesets: {
        osm: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
        }),
        sat: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        })
      }
    }
  },
  watch: {
    colour: function() {
      this.redrawFlightPath();
    },
    src: function() {
      this.getData().then(this.redrawFlightPath);
    },
    movement: function() {
      this.getData().then(this.redrawFlightPath);
    }
  },
  computed: {
    formattedAircraftType() {
      if (this.movement.aircraft_type.length === 0) return '';
      return this.movement.aircraft_type.charAt(0).toUpperCase() + this.movement.aircraft_type.slice(1).replace('_', '-');
    }
  },
  mounted() {
    window.setTimeout( () => {
      this.initMap();
      this.getData().then(this.redrawFlightPath);
    }, 500)
  }
}
</script>

<style scoped>
  #map {
    height: 500px;
    margin: 0;
  }
</style>
