<template>
  <!-- start Movements -->
  <section id="movements">
    <div class="row">
      <div class="col-12 col-md-7 col-lg-5 col-xl-3">
        <fieldset class="form-group">
          <label>Filter by day</label>
          <datepicker
              v-model="pickedDate"
              inputFormat="dd/MM/yyyy"
          />
        </fieldset>
      </div>
    </div>
    <div class="row" id="event-timeline" v-if="seen">
      <div class="col-12">
        <div class="card">
          <div class="card-content collapse show">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="m-1 mt-md-0 float-right">
                    <button type="button"
                            id="event-create-button"
                            class="btn btn-warning modal-event"
                            data-toggle="modal"
                            data-target="#id-event-form"
                            @click="movementBeingEdited = null">
                      <strong><i class="fa fa-plus" aria-hidden="true"></i></strong> Create event
                    </button>
                  </div>
                </div>
              </div>
              <Movement
                  v-for="(movement,index) in movements"
                  :movement="movement"
                  :index="index"
                  :key="movement.event_id"
                  :id="movement.event_id"
                  :images="[]"
                  @show-flight-map="updateFlightMap"
                  @edit-movement="editMovement"
              ></Movement>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <MovementDialogue :movement="movementBeingEdited"></MovementDialogue>
  <FlightMap v-if="currentFlightMapMovement !== null" :movement="currentFlightMapMovement" colour="#6666CC"></FlightMap>
  <!-- end Movements -->
</template>

<script>
import { ref } from 'vue'
import Movement from "@/components/Movement";
import MovementDialogue from "@/components/MovementDialog";
import APIService from "@/services/APIService";
import FlightMap from "@/components/FlightMap";

export default {
  name: "Movements",
  data() {
    return {
      seen:                     true,
      pickedDate:               ref(new Date()),
      movements:                [],
      currentFlightMapMovement: null,
      movementBeingEdited:      null,
      // movements: [
      //   {
      //     id: 1,
      //     tailNumber: 'GF-123Y',
      //     movementType: 'take-off',
      //     aircraftType: 'Fixed-wing',
      //     isResident: true,
      //     isEmergency: false,
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      //   {
      //     id: 2,
      //     tailNumber: 'GF-123Y',
      //     movementType: 'landing',
      //     aircraftType: 'Fixed-wing',
      //     isResident: false,
      //     isEmergency: true,
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      //   {
      //     id: 3,
      //     tailNumber: 'GF-123Y',
      //     movementType: 'landing',
      //     aircraftType: 'Helicopter',
      //     isResident: false,
      //     isEmergency: false,
      //     isBreach: true,
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      //   {
      //     id: 4,
      //     movementType: 'Unidentified movement',
      //     tailNumber: 'GF-123Y',
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      //   {
      //     id: 5,
      //     tailNumber: 'GF-123Y',
      //     movementType: 'touch-and-go',
      //     aircraftType: 'Fixed-wing',
      //     isResident: true,
      //     isEmergency: false,
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      //   {
      //     id: 6,
      //     tailNumber: 'GF-123Y',
      //     movementType: 'hot-refuel',
      //     aircraftType: 'Fixed-wing',
      //     isResident: true,
      //     isEmergency: true,
      //     dateStr: 'Sat, 14th Nov 2020 12:35 UTC',
      //     images: {
      //       1: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       2: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       3: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       4: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       5: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       },
      //       6: {
      //         url: 'https://i.pinimg.com/originals/14/7a/0e/147a0e4b07bf3ae4def2b49def351fb3.jpg'
      //       }
      //     }
      //   },
      // ]
      filter: {
        fields: {},
        order: { started_at: 'ASC' },
        paginate: { start: 0, limit: 1000 }
      },
      error: ""
    }
  },
  methods: {
    setDateFilter: function(date) {
      const min = new Date(date.getTime());
      min.setHours(0,0,0,0);

      const max = new Date(date.getTime());
      max.setHours(23,59,59,999);

      this.filter.fields.started_at = {
        min: min.toISOString(),
        max: max.toISOString()
      }
    },
    refreshMovements: async function() {
      try {
        this.movements = await APIService.getMovements(this.filter);
      } catch(e) {
        this.error = e.message;
      }
    },
    updateFlightMap: function(event_id) {
      this.currentFlightMapMovement = this.movements.find((movement) => movement.event_id === event_id);
    },
    editMovement: function(event_id) {
      this.movementBeingEdited = this.movements.find((movement) => movement.event_id === event_id);
    }
  },
  components: {
    FlightMap,
    Movement,
    MovementDialogue
  },
  async created() {
    this.setDateFilter(new Date());
    await this.refreshMovements();
  },
  watch: {
    pickedDate: function(val) {
      this.setDateFilter(val);
      this.refreshMovements();
    }
  }
}

</script>

<style scoped>
#movements {
  min-height: 350px;
  margin: 0;
  padding: 0;
}
</style>

